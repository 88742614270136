import React from 'react'
import PropTypes from 'prop-types'

import styles from './Feedback.module.scss'

class Feedback extends React.Component {
  constructor(props) {
    super(props)
    this._feedbackElement = React.createRef()
  }

  componentDidMount = () => {
    this._scrollIntoView()
  }

  _scrollIntoView = () => {
    const { current } = this._feedbackElement

    if (current) {
      current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  render() {
    const { children, type } = this.props

    return (
      <div
        className={[
          styles.feedback,
          type === 'success' ? styles.success : styles.error,
        ].join(' ')}
        ref={this._feedbackElement}
      >
        {children}
      </div>
    )
  }
}

Feedback.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'message']).isRequired,
}

export default Feedback
