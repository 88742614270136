import React from 'react'
import PropTypes from 'prop-types'

import FormContext from './FormContext'

import styles from './Toggle.module.scss'

class Checkbox extends React.Component {
  static contextType = FormContext

  constructor(props) {
    super(props)
    this._controllerRef = React.createRef()
  }

  state = {
    isFocused: false,
    isValid: false,
  }

  componentDidMount = () => {
    const { current } = this._controllerRef
    this.setState({ isValid: current.validity.valid })
  }

  componentDidUpdate = () => {
    this._setValidity()
  }

  _setValidity = () => {
    const { current } = this._controllerRef
    const { isValid } = this.state

    if (current && current.validity.valid !== isValid) {
      this.setState({ isValid: current.validity.valid })
    }
  }

  _handleChange = (e) => {
    const { name, onChange } = this.props
    const { setData } = this.context
    const { checked, validity } = e.target

    if (onChange) {
      onChange(checked)
    }

    setData('fieldsValues', name, checked)
    setData('fieldsTouched', name, true)
    this.setState({ isValid: validity.valid })
  }

  _handleFocus = () => {
    this.setState({ isFocused: true })
  }

  _handleBlur = (e) => {
    const { name } = this.props
    const { setData } = this.context
    const { validity } = e.target

    setData('fieldsTouched', name, true)

    this.setState({
      isFocused: false,
      isValid: validity.valid,
    })
  }

  render() {
    const { disabled, helper, label, name, required, size, toggle } = this.props
    const { isFocused, isValid } = this.state
    const { fieldsTouched, fieldsValues, formTouched } = this.context

    const classNames = {
      wrapper: [
        styles.wrapper,
        toggle ? styles.toggle : styles.checkbox,
        styles[size],
        formTouched || fieldsTouched[name] ? styles.touched : null,
        isFocused ? styles.focus : null,
        !isValid ? styles.invalid : null,
        disabled ? styles.disabled : null,
      ].join(' '),
    }

    return (
      <label className={classNames.wrapper}>
        <div className={styles.label}>
          {label}
          {helper && <span className={styles.helper}>{helper}</span>}
        </div>

        <input
          checked={fieldsValues[name] || false}
          className={styles.field}
          name={name}
          onBlur={this._handleBlur}
          onChange={this._handleChange}
          required={required}
          ref={this._controllerRef}
          type="checkbox"
        />

        <div className={styles.controller} />
      </label>
    )
  }
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  helper: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'regular']),
  toggle: PropTypes.bool,
}

Checkbox.defaultProps = {
  size: 'regular',
}

export default Checkbox
