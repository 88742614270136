import React from 'react'
import Helmet from 'react-helmet'

import ContactForm from '../components/ContactForm'
import ContactInfo from '../components/PageContact/ContactInfo'
import Root from '../layouts/Root'
import Testimonials from '../components/Testimonials'

import config from '../config'

const Contact = () => (
  <Root>
    {/* prettier-ignore */}
    <Helmet>
      <title>{`Contact | ${config.title}`}</title>
      <meta property="og:title" content={`Contact | ${config.title}`} />
      <meta name="description" content="Neem vandaag nog contact op met W&R beveiliging uit Uden voor een vrijblijvend adviesgesprek. Hét professionele beveiligingsbedrijf van Nederland." />
      <meta property="og:description" content="Neem vandaag nog contact op met W&R beveiliging uit Uden voor een vrijblijvend adviesgesprek. Hét professionele beveiligingsbedrijf van Nederland." />
    </Helmet>

    <main>
      <ContactInfo />
      <ContactForm />
      <Testimonials filled />
    </main>
  </Root>
)

export default Contact
