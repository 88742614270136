import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import styles from './Controls.module.scss'

const Controls = ({
  activeTestimonial,
  controlStyle,
  setActiveTestimonial,
  testimonials,
}) => {
  if (controlStyle === 'bullets') {
    return (
      <div className={styles.bullets}>
        {testimonials.map((testimonial, index) => {
          return (
            <button
              key={testimonial.id}
              className={[
                styles.bulletsButton,
                activeTestimonial === index + 1
                  ? styles.bulletsButtonActive
                  : null,
              ].join(' ')}
              onClick={() => {
                setActiveTestimonial(
                  index + 1 < activeTestimonial ? 'previous' : 'next',
                  index + 1
                )
              }}
            >
              <span />
            </button>
          )
        })}
      </div>
    )
  }

  if (controlStyle === 'chevrons') {
    return (
      <div className={styles.chevrons}>
        <button
          className={styles.chevronsButton}
          onClick={() => setActiveTestimonial('previous')}
        >
          <Icon name="chevronLeftLine" size={40} />
        </button>

        <button
          className={styles.chevronsButton}
          onClick={() => setActiveTestimonial('next')}
        >
          <Icon name="chevronRightLine" size={40} />
        </button>
      </div>
    )
  }

  return null
}

Controls.propTypes = {
  activeTestimonial: PropTypes.number.isRequired,
  controlStyle: PropTypes.oneOf(['bullets', 'chevrons']).isRequired,
  setActiveTestimonial: PropTypes.func.isRequired,
  testimonials: PropTypes.array.isRequired,
}

export default Controls
