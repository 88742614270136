import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import Button from '../../Button'
import GlobalContext from '../../../utils/globalContext'

import styles from './ContactInfo.module.scss'

const query = graphql`
  query {
    image: file(relativePath: { eq: "assets/images/dienst_evenement.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 624, maxWidth: 1000, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ContactInfo = () => (
  <GlobalContext.Consumer>
    {({ i18n }) => (
      <div className={styles.contactInfo}>
        <div className={styles.infoWrapper}>
          <div className={styles.info}>
            <h1 className="t-heading-m">Contact</h1>

            <div className={styles.clientAddress}>
              <div className={styles.clientName}>{i18n.misc.clientName}</div>
              {i18n.misc.address}
            </div>

            <div className={styles.clientContact}>
              <a
                href={`tel:${i18n.misc.phoneHtml}`}
                className={styles.clientPhone}
              >
                {i18n.misc.phoneHuman}
              </a>

              <a
                href={`mailto:${i18n.misc.email}`}
                className={styles.clientEmail}
              >
                {i18n.misc.email}
              </a>
            </div>

            <Button
              type="anchor"
              to={i18n.misc.addressMap}
              color="white"
              target="_blank"
            >
              Routebeschrijving
            </Button>
          </div>
        </div>

        <StaticQuery
          query={query}
          render={({ image }) => (
            <Img
              fluid={image.childImageSharp.fluid}
              className={styles.image}
              alt="contact"
            />
          )}
        />
      </div>
    )}
  </GlobalContext.Consumer>
)

export default ContactInfo
