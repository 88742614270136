import React from 'react'
import PropTypes from 'prop-types'

import GlobalContext from '../../utils/globalContext'

import TestimonialSlider from '../TestimonialSlider'

import styles from './Testimonials.module.scss'

const Testimonials = ({ filled }) => {
  // prettier-ignore
  const classList = {
    testimonials: [
      styles.testimonials, filled ? styles.filled : null
    ].join(' '),
  }

  return (
    <GlobalContext.Consumer>
      {({ i18n }) => (
        <div className={classList.testimonials}>
          <h2 className="t-heading-sub">Referenties</h2>

          <TestimonialSlider
            controlStyle="chevrons"
            testimonials={i18n.testimonials}
          />
        </div>
      )}
    </GlobalContext.Consumer>
  )
}

Testimonials.propTypes = {
  filled: PropTypes.bool,
}

export default Testimonials
