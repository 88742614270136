import React from 'react'
import PropTypes from 'prop-types'

import FormContext from './FormContext'

import styles from './Form.module.scss'

class Select extends React.Component {
  static contextType = FormContext

  constructor(props) {
    super(props)
    this._controllerRef = React.createRef()
  }

  state = {
    isFocused: false,
    isValid: false,
  }

  componentDidMount = () => {
    const { current } = this._controllerRef
    this.setState({ isValid: current.validity.valid })
  }

  componentDidUpdate = () => {
    this._setValidity()
  }

  _setValidity = () => {
    const { current } = this._controllerRef
    const { isValid } = this.state

    if (current && current.validity.valid !== isValid) {
      this.setState({ isValid: current.validity.valid })
    }
  }

  _handleChange = (e) => {
    const { name, onChange } = this.props
    const { setData } = this.context
    const { value, validity } = e.target

    if (onChange) {
      onChange(value)
    }

    setData('fieldsValues', name, value)
    this.setState({ isValid: validity.valid })
  }

  _handleFocus = () => {
    this.setState({ isFocused: true })
  }

  _handleBlur = (e) => {
    const { name } = this.props
    const { setData } = this.context
    const { validity } = e.target

    setData('fieldsTouched', name, true)

    this.setState({
      isFocused: false,
      isValid: validity.valid,
    })
  }

  render() {
    const {
      disabled,
      helper,
      label,
      name,
      options,
      required,
      size,
    } = this.props
    const { isFocused, isValid } = this.state
    const { fieldsTouched, fieldsValues, formTouched } = this.context

    const classNames = {
      wrapper: [
        styles.wrapper,
        styles.select,
        styles[size],
        formTouched || fieldsTouched[name] ? styles.touched : null,
        isFocused ? styles.focus : null,
        !isValid ? styles.invalid : null,
        disabled ? styles.disabled : null,
      ].join(' '),
    }

    return (
      <div className={classNames.wrapper}>
        {label && <label className={styles.label}>{label}</label>}

        <div className={styles.field}>
          <select
            className={styles.controller}
            name={name}
            onBlur={this._handleBlur}
            onChange={this._handleChange}
            onFocus={this._handleFocus}
            ref={this._controllerRef}
            required={required}
            value={fieldsValues[name] || ''}
          >
            <option disabled={required} value="" />

            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {helper && <div className={styles.helper}>{helper}</div>}
      </div>
    )
  }
}

Select.propTypes = {
  disabled: PropTypes.bool,
  helper: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'regular']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

Select.defaultProps = {
  size: 'regular',
}

export default Select
