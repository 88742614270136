import React from 'react'
import PropTypes from 'prop-types'

import styles from './TestimonialSlider.module.scss'

const Testimonial = ({
  authorFunction,
  authorName,
  direction,
  excerpt,
  text,
}) => {
  const classLists = {
    testimonial: [
      styles.testimonial,
      direction === 'next' ? styles.next : styles.previous,
    ].join(' '),
  }

  return (
    <div className={classLists.testimonial}>
      <div className={styles.excerpt}>{excerpt}</div>
      <div className={[styles.text].join(' ')}>{text}</div>
      <div className={styles.author}>
        <span>{authorName}</span>
        {authorFunction && <span>{authorFunction}</span>}
      </div>
    </div>
  )
}

Testimonial.propTypes = {
  authorFunction: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['previous', 'next']),
  excerpt: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default Testimonial
