import React from 'react'
import PropTypes from 'prop-types'

import FormContext from './FormContext'
import GlobalContext from '../../utils/globalContext'

class Form extends React.Component {
  static contextType = GlobalContext

  state = {
    fieldsTouched: {},
    fieldsValues: this.props.initialValues,
  }

  _setData = (target, name, value) => {
    const { onChange } = this.props
    const { fieldsValues } = this.state
    const data = this.state[target]

    data[name] = value

    this.setState({ [target]: data })

    if (onChange && target === 'fieldsValues') {
      onChange({
        ...fieldsValues,
        ...{ [name]: value },
      })
    }
  }

  _handleSubmit = (e) => {
    e.persist()
    e.preventDefault()

    this.setState({ formTouched: true })

    const {
      ongewenst,
      ongewenstalt,
      achternaam,
      emailadres,
    } = e.target.elements

    if (
      !ongewenst.value &&
      !ongewenstalt.value &&
      !achternaam.value &&
      !emailadres.value &&
      e.target.checkValidity()
    ) {
      this.props
        .onSubmit({
          values: this.state.fieldsValues,
          language: this.context.language,
        })
        .then(() => {
          this.setState({
            fieldsTouched: {},
            fieldsValues: {},
            formTouched: false,
          })
        })
        .catch(() => {
          return false
        })
    }
  }

  render() {
    const formContext = {
      fieldsTouched: this.state.fieldsTouched,
      fieldsValues: this.state.fieldsValues,
      formTouched: this.state.formTouched,
      setData: this._setData,
    }

    return (
      <FormContext.Provider value={formContext}>
        <form onSubmit={this._handleSubmit} noValidate>
          <label className="ongewenst">
            Dit veld leeglaten / Leave this field empty / Dieses Feld leer
            lassen
            <input type="text" name="ongewenst" tabIndex="-1" />
          </label>
          <input type="hidden" name="ongewenstalt" />

          {this.props.children}

          <label class="ookongewenst" for="achternaam">
            Uw achternaam
          </label>
          <input
            class="ookongewenst"
            autocomplete="off"
            type="text"
            id="achternaam"
            name="achternaam"
            placeholder="Uw achternaam"
          />

          <label class="ookongewenst" for="emailadres">
            Uw emailadres
          </label>
          <input
            class="ookongewenst"
            autocomplete="off"
            type="email"
            id="emailadres"
            name="emailadres"
            placeholder="Uw emailadres"
          />
        </form>
      </FormContext.Provider>
    )
  }
}

Form.propTypes = {
  initialValues: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

Form.defaultProps = {
  initialValues: {},
}

export default Form
