import React from 'react'
import axios from 'axios'
import { StaticQuery, graphql } from 'gatsby'

import Button from '../Button'
import Feedback from '../Feedback'
import GlobalContext from '../../utils/globalContext'
import { Form, Input, Select, Textarea } from '../Form'

import styles from './ContactForm.module.scss'

const query = graphql`
  query {
    diensten: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^/diensten/.+/" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

class ContactForm extends React.Component {
  static contextType = GlobalContext

  state = {
    error: null,
    message: null,
  }

  _handleSubmit = async (formData) => {
    try {
      await this.setState({
        error: null,
        message: null,
      })

      await axios.post(
        'https://staticmailer.pexel.nl/yoJ002Oa5BRj',
        formData.values
      )

      if (window.ga) {
        window.ga(
          'send',
          'event',
          'contactformulier',
          'verzenden',
          this.context.urlPathname
        )
      }

      await this.setState({
        message:
          'Uw bericht is verzonden, wij nemen zo spoedig mogelijk contact met u op.',
      })
    } catch (error) {
      if (window.ga) {
        window.ga(
          'send',
          'event',
          'contactformulier',
          'error',
          this.context.urlPathname
        )
      }

      await this.setState({
        error:
          'Er is een fout opgetreden en uw bericht is niet verzonden.\nProbeer het later nog eens of stuur een e-mail naar info@wenrbeveiliging.nl.',
      })

      throw new Error(error.message)
    }
  }

  render() {
    const { error, message } = this.state

    return (
      <div className={styles.contactForm}>
        <div className={styles.title}>
          <h2 className="t-heading-sub">Contact</h2>
          <span className="t-heading-l">
            Neem vrijblijvend
            <br />
            contact met ons op!
          </span>
        </div>

        {error && <Feedback type="error">{error}</Feedback>}
        {message && <Feedback type="success">{message}</Feedback>}

        <Form onSubmit={this._handleSubmit}>
          <div className={styles.formColumnWrapper}>
            <div className={styles.formColumn}>
              <Input label="Naam" name="name" required type="text" />

              <Input label="Adres" name="address" type="text" />

              <Input label="Plaats" name="city" type="text" />

              <Input label="E-mailadres" name="email" required type="email" />
            </div>

            <div className={styles.formColumn}>
              <Input label="Telefoon" name="phone" required type="tel" />

              <StaticQuery
                query={query}
                render={(data) => (
                  <Select
                    label="Aanvraag voor"
                    required
                    name="subject"
                    options={[
                      ...data.diensten.edges.map(({ node }) => ({
                        label: node.frontmatter.title,
                        value: node.frontmatter.title
                          .toLowerCase()
                          .replace(' ', ''),
                      })),
                      {
                        label: 'Anders, namelijk:',
                        value: 'anders',
                      },
                    ]}
                  />
                )}
              />

              <Textarea name="message" required />
            </div>

            <Button type="submit" color="confirm">
              Verzenden
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

export default ContactForm
