import React from 'react'
import PropTypes from 'prop-types'

import Controls from './Controls'
import Testimonial from './Testimonial'

import styles from './TestimonialSlider.module.scss'

class TestimonialSlider extends React.Component {
  state = {
    activeTestimonial: 1,
    direction: 'next',
  }

  componentDidMount = () => {
    this._setActiveTestimonial()
  }

  _setActiveTestimonial = (direction, number) => {
    const { activeTestimonial } = this.state
    const { testimonials } = this.props

    if (number) {
      this.setState({
        activeTestimonial: number,
        direction,
      })
    } else {
      switch (direction) {
        case 'previous':
          if (activeTestimonial - 1 < 1) {
            this.setState({
              activeTestimonial: testimonials.length,
              direction,
            })
          } else if (activeTestimonial - 1 >= 1) {
            this.setState({
              activeTestimonial: activeTestimonial - 1,
              direction,
            })
          }
          break

        case 'next':
          if (activeTestimonial + 1 > testimonials.length) {
            this.setState({ activeTestimonial: 1, direction })
          } else if (activeTestimonial + 1 <= testimonials.length) {
            this.setState({
              activeTestimonial: activeTestimonial + 1,
              direction,
            })
          }
          break

        default:
          this.setState({
            activeTestimonial:
              Math.floor(Math.random() * testimonials.length) + 1,
            direction: 'next',
          })
          break
      }
    }
  }

  _renderActiveTestimonial = () => {
    const { activeTestimonial, direction } = this.state
    const { testimonials } = this.props

    const activeTestimonialIndex = testimonials[activeTestimonial - 1]

    return (
      <Testimonial
        authorFunction={activeTestimonialIndex.authorFunction}
        authorName={activeTestimonialIndex.authorName}
        direction={direction}
        excerpt={activeTestimonialIndex.excerpt}
        key={activeTestimonialIndex.id}
        text={activeTestimonialIndex.text}
      />
    )
  }

  render() {
    const { controlStyle, testimonials } = this.props
    const { activeTestimonial } = this.state

    // prettier-ignore
    const classList = {
      testimonialSlider: [
        styles.testimonialSlider,
        styles[controlStyle]
      ].join(' '),
    }

    if (testimonials.length > 0) {
      return (
        <div className={classList.testimonialSlider}>
          {this._renderActiveTestimonial()}
          <Controls
            activeTestimonial={activeTestimonial}
            setActiveTestimonial={this._setActiveTestimonial}
            controlStyle={controlStyle}
            testimonials={testimonials}
          />
        </div>
      )
    }

    return null
  }
}

TestimonialSlider.propTypes = {
  controlStyle: PropTypes.oneOf(['bullets', 'chevrons']),
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      authorFunction: PropTypes.string,
      authorName: PropTypes.string.isRequired,
      excerpt: PropTypes.string,
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
}

TestimonialSlider.defaultProps = {
  controlStyle: 'bullets',
  testimonials: [],
}

export default TestimonialSlider
