import React from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import moment from 'moment'

import FormContext from './FormContext'

import styles from './Form.module.scss'

class DateRange extends React.Component {
  static contextType = FormContext

  // prettier-ignore
  state = {
    isFocused: false,
    isValid: false,
    value: this.context.fieldsValues[this.props.name] || [new Date(), new Date()],
  }

  componentDidMount = () => {
    this._setValidity()
  }

  _setValidity = (value) => {
    const { max, min, required } = this.props
    const newVal = value || this.state.value

    let isValid

    // prettier-ignore
    if (
      (!required || (required && newVal)) &&
      (!min || (min && newVal && moment(newVal[0]).isSameOrAfter(moment(min), 'day'))) &&
      (!max || (max && newVal && moment(newVal[1]).isSameOrBefore(moment(max), 'day')))
    ) {
      isValid = true
    } else {
      isValid = false
    }

    this.setState({ isValid })
  }

  _handleChange = (dates) => {
    const { name, onChange } = this.props
    const { setData } = this.context

    if (onChange) {
      onChange(dates)
    }

    setData('fieldsValues', name, dates)
    this.setState({ value: dates })
    this._setValidity(dates)
  }

  _handleFocus = () => {
    this.setState({ isFocused: true })
  }

  _handleBlur = () => {
    const { name } = this.props
    const { setData } = this.context

    setData('fieldsTouched', name, true)

    this.setState({ isFocused: false })
  }

  render() {
    const {
      disabled,
      label,
      locale,
      max,
      min,
      name,
      required,
      size,
    } = this.props
    const { isFocused, isValid, value } = this.state
    const { fieldsTouched, formTouched } = this.context

    const classNames = {
      wrapper: [
        styles.wrapper,
        styles.daterange,
        styles[size],
        formTouched || fieldsTouched[name] ? styles.touched : null,
        isFocused ? styles.focus : null,
        !isValid ? styles.invalid : null,
        disabled ? styles.disabled : null,
      ].join(' '),
    }

    return (
      <div className={classNames.wrapper}>
        {label && <label className={styles.label}>{label}</label>}

        <div className={styles.field}>
          <DateRangePicker
            className={styles.date}
            disabled={disabled}
            locale={locale}
            maxDate={max}
            minDate={min}
            name={name}
            onChange={this._handleChange}
            onFocus={this._handleFocus}
            onBlur={this._handleBlur}
            required={required}
            showLeadingZeros
            value={value}
          />
        </div>
      </div>
    )
  }
}

DateRange.propTypes = {
  label: PropTypes.string,
  locale: PropTypes.string,
  max: PropTypes.any,
  min: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'regular']),
  wideLabel: PropTypes.bool,
}

DateRange.defaultProps = {
  locale: 'nl-NL',
  size: 'regular',
}

export default DateRange
